
import { Component, Prop, Vue } from "vue-property-decorator";

interface PathElement {
  icon: string;
  text: string;
  textTop: boolean;
}

@Component
export default class PathScroller extends Vue {
  @Prop() private pathElements!: PathElement[];
  @Prop() private PathscrollerCustomClass!: string;
  @Prop() private PathscrollerV2!: boolean;

  public $refs!: {
    pathContainer: HTMLDivElement;
  };

  private pos: { top: number; left: number; x: number; y: number } = {
    top: 0,
    left: 0,
    x: 0,
    y: 0
  };

  private mouseMoveHandler = (e: MouseEvent): void => {
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;
    // Scroll the element
    this.$refs.pathContainer.scrollTop = this.pos.top - dy;
    this.$refs.pathContainer.scrollLeft = this.pos.left - dx;
  };

  private mouseUpHandler = (): void => {
    this.$refs.pathContainer.removeEventListener(
      "mousemove",
      this.mouseMoveHandler
    );
    this.$refs.pathContainer.removeEventListener(
      "mouseup",
      this.mouseUpHandler
    );
    this.$refs.pathContainer.style.cursor = "grab";
  };

  private mouseDownHandler = (e: MouseEvent): void => {
    this.pos = {
      // The current scroll
      left: this.$refs.pathContainer.scrollLeft,
      top: this.$refs.pathContainer.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY
    };

    this.$refs.pathContainer.style.cursor = "grabbing";
    this.$refs.pathContainer.style.userSelect = "none";
    this.$refs.pathContainer.addEventListener(
      "mousemove",
      this.mouseMoveHandler
    );
    this.$refs.pathContainer.addEventListener("mouseup", this.mouseUpHandler);
  };
}
