
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LeightonListItem extends Vue {
  @Prop() private icon!: string;
  @Prop() private title!: string;
  @Prop() private hideTitle!: boolean;
  @Prop() private text!: string;
  @Prop() private listItem!: number;
  @Prop() private hasCallout!: boolean;
  @Prop() private roundIcon!: boolean;

  getAltText(): string {
    const adjustedListItem = this.listItem + 1;
    if (this.title === undefined) {
      return "list item " + adjustedListItem + " icon";
    } else {
      return this.title + " icon";
    }
  }
}
