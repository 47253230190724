
import { Component, Prop, Vue } from "vue-property-decorator";
import { ListItem as IListItem } from "@/interfaces/VerticalListItem.interface";
import VerticalList from "@/components/common-ui/VerticalList.vue";
import CarouselList from "@/components/common-ui/CarouselList.vue";

@Component({
  components: { VerticalList, CarouselList }
})
export default class IconList extends Vue {
  @Prop() private listItems!: IListItem[];
  @Prop() private customClass!: string;
  @Prop() private componentNumber!: number;
  @Prop() private hasCallout!: boolean;
}
