
import { Component, Prop, Vue } from "vue-property-decorator";
import { ListItem as IListItem } from "@/interfaces/VerticalListItem.interface";
import LeightonListItem from "@/components/common-ui/LeightonListItem.vue";

interface CarouselListItem extends IListItem {
  id: number;
}

@Component({
  components: { LeightonListItem }
})
export default class CarouselList extends Vue {
  @Prop() private listItems!: IListItem[];
  @Prop() private customClass!: string;
  @Prop() private componentNumber!: number;
  @Prop() private hasCallout!: boolean;

  private currentElementIndex = 0;

  public transitionName = "";

  setActive(currentItem: number): void {
    currentItem > this.currentElementIndex
      ? (this.transitionName = "slide-next")
      : (this.transitionName = "slide-prev");
    this.currentElementIndex = currentItem;
  }

  nextElement(direction: number): void {
    direction === 1
      ? (this.transitionName = "slide-next")
      : (this.transitionName = "slide-prev");
    const len = this.listItems.length;
    this.currentElementIndex =
      (this.currentElementIndex + (direction % len) + len) % len;
  }

  getLabelText(index: number): string {
    const adjustedIndex = index + 1;

    if (this.listItems[index].title === undefined) {
      return "Activate slide " + adjustedIndex;
    } else {
      return "Activate slide - " + this.listItems[index].title;
    }
  }

  get ComponentNumber(): string {
    return `carousel-${this.componentNumber}`;
  }

  get CustomClass(): string {
    return `carousel ${this.customClass}`;
  }
}
