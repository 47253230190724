
import { Component, Prop, Vue } from "vue-property-decorator";
import { ListItem as IListItem } from "@/interfaces/VerticalListItem.interface";
import LeightonListItem from "@/components/common-ui/LeightonListItem.vue";

@Component({
  components: { LeightonListItem }
})
export default class VerticalList extends Vue {
  @Prop() private listItems!: IListItem[];
  @Prop() private customClass!: string;
  @Prop() private componentNumber!: number;
}
